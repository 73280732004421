<template>
  <v-row>
    <v-col cols="12">
      <loading-spinner v-model="userLoading" />
      <v-simple-table v-if="orders.length" dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th> Order ID</th>
            <th> Payment method </th>
            <th> Payment status </th>
<!--            <th> Order status </th>-->
            <th> amount </th>
            <th> actions </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(order, index) in orders" :key="'orders'+index">
            <td>{{ order.order_number }}</td>
            <td>{{ order.payment_method }}</td>
            <td v-html="statusBadge(order.payment_status)"></td>
<!--            <td v-html='statusBadge(order.status)'></td>-->
            <td>{{ order.total | currency }}</td>
            <td>
              <v-btn :to="{name: 'Invoice', params: { invoice : order.id }}" color="secondary">View Products</v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        <v-sheet>
          <v-alert
            prominent
            text
            type="info"
          >
            <span>You did not ordered anything from us</span>
          </v-alert>
        </v-sheet>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { userOrders } from '@/api/user';

import LoadingSpinner from '@/components/Utils/LoadingSpinner';

export default {
  name: 'Orders',
  components: { LoadingSpinner },
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
    userLoading: false
  }),
  computed: {
    ...mapGetters({
      orders: 'user/userOrders'
    }),
  },
  methods: {
    ...mapActions({
      orderData: 'user/userOrders'
    }),
    async getOrders() {
      if (this.orders.length) return;

      this.userLoading = true;
      let { data } = await userOrders();
      await this.orderData(data.data);
      this.userLoading = false;

    },
  },
  async mounted() {

    await this.getOrders();
  }
};
</script>

<style scoped>

</style>
